<template>
  <v-container fill-height class="my-10">
    <v-row justify="center" align="center">
      <v-col cols="12" class="mb-10">
        <div class="text-center">
          <h1 class="display-4 font-weight-thin" v-text="store.name"/>
          <p v-text="store.description"></p>
        </div>
      </v-col>
      <v-col cols="12" md="6" offset-md="1" class="mb-12">
        <v-list class="mb-12">
          <v-list-item>
            <v-list-item-icon>
              <v-icon>
                mdi-map-marker
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ store.name }}</v-list-item-title>
              <v-list-item-subtitle id="storeAddress">{{ store.address }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>
                mdi-phone
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title id="phone-number">{{ store.phone }}</v-list-item-title>
              <v-list-item-subtitle>Phone number</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-icon>
                mdi-email
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title id="email">{{ store.email }}</v-list-item-title>
              <v-list-item-subtitle>Email address</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-for="i in 4" :key="i">
            <v-list-item-icon>
              <v-icon v-if="i === 1">
                mdi-clock-time-seven
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title :id="i - 1" v-if="!store.openingHours[i-1].open">CLOSED</v-list-item-title>
              <v-list-item-title v-else :id="i - 1">{{ store.openingHours[i - 1].openingTime | niceTime }} -
                {{ store.openingHours[i - 1].closingTime | niceTime }}
              </v-list-item-title>
              <v-list-item-subtitle>{{ store.openingHours[i - 1].day | toDay }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content v-if="i+3 < 7">
              <v-list-item-title :id="i+3" v-if="!store.openingHours[i+3].open">CLOSED</v-list-item-title>
              <v-list-item-title v-else :id="i+3">{{ store.openingHours[i + 3].openingTime | niceTime }} -
                {{ store.openingHours[i + 3].closingTime | niceTime }}
              </v-list-item-title>
              <v-list-item-subtitle>{{ store.openingHours[i + 3].day | toDay }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapState} from 'vuex'
import moment from 'moment'
import {createEnquiry} from '@/graphql'

export default {
  data: () => ({
    valid: null,
    form: {
      name: null,
      email: null,
      phone: null,
      message: null
    },
    loading: false,
    rules: {
      required: v => !!v || 'This field is required',
      email: v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    },
    snackbar: false,
    snackbarColor: 'info',
    snackbarMessage: null
  }),
  filters: {
    toDay(value) {
      return moment().day(value + 1).format("dddd")
    },
    niceTime(value) {
      return value.slice(0, 5)
    }
  },
  computed: mapState(['store']),
  methods: {
    submitForm() {
      this.loading = true
      this.$apollo.mutate({
        mutation: createEnquiry,
        variables: this.form
      }).then((result) => {
        if (result) {
          this.loading = false
          this.snackbar = true
          this.snackbarColor = 'success'
          this.snackbarMessage = 'Message sent!'
        }
      }).catch((error) => {
        this.loading = false
        this.snackbar = true
        this.snackbarColor = 'error'
        this.snackbarMessage = error.message.replace('GraphQL error', '')
      })
    }
  }
}
</script>

<style scoped>
.no-transform {
  font-size: 16px;
  text-transform: none !important;
}
</style>

